<template>
  <AppCard
    v-if="isOpen"
    max-width="500"
    class="cookies-card"
  >
    <h4 class="title">This website uses cookies</h4>
    <p class="cookies-text">
      IMPORTANT: this page uses cookies, profiling and automated data processing. We use this
      information all of it to make your browsing experience more personal and convenient and
      deliver content tailored to your interests, to optimize website functionality, to analyze our
      traffic. You may delete and block all cookies from this site as described here, however
      remember in some cases, they are essential to making the site work properly. To learn more
      about cookies, privacy and use of this website please read our
      <AppTextLink
        :show-divider="false"
        new-tab
        class="cookies-link"
        :to="privacy"
      >
        Online Privacy Notice
      </AppTextLink>
      and
      <AppTextLink
        :show-divider="false"
        new-tab
        class="cookies-link"
        :to="terms"
      >
        Terms of Use
      </AppTextLink>
    </p>
    <AppButton
      outlined
      :icon="config.icons.ACCEPT_COOKIES"
      content-class="capitalize font-semibold text-lg"
      @click="accept"
    >
      Accept
    </AppButton>
  </AppCard>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import AppButton from '@/components/App/AppButton'
import AppCard from '@/components/App/Cards/AppCard'
import AppTextLink from '@/components/App/Text/AppTextLink'
import config from '@/config'

const privacy = 'https://www.luxoft.com/online-privacy-policy'
const terms = 'https://www.luxoft.com/terms-of-use'

const isOpen = ref(false)
const accept = () => {
  localStorage.accept_cookies = true
  isOpen.value = false
}

onMounted(() => {
  isOpen.value = !localStorage.accept_cookies
})
</script>

<style scoped lang="sass">
.cookies-card
  @apply p-6 rounded-card flex flex-col gap-6 bg-secondary-bg bg-opacity-100 shadow z-20
  font-size: 14px
.title
  @apply capitalize
  font-size: 28px
.cookies-text
  @apply text-justify
.cookies-link
  @apply capitalize underline inline-block
</style>
